import React from "react";
import cx from "classnames";
import Photo from "@components/photo";
import CustomLink from "@components/link";
const CTA = ({ data = {} }) => {
  const { sectionTitle, cta, backgroundColor } = data;
  return (
    <section
      className={cx(
        "cta",
        "p-10",
        "py-[125px]",
        `bg-${backgroundColor?.value}`,
        "relative",
        "z-0"
      )}
      data-theme={backgroundColor?.title.toLowerCase()}
    >
      <div className={cx("text-center", "site-container")}>
        {sectionTitle && (
          <div className={cx("text-lg-1-mobile", "md:text-lg-1")}>
            {sectionTitle}
          </div>
        )}
        {cta && <CustomLink className="cta-link mt-5" link={cta[0]} />}
      </div>
    </section>
  );
};

export default CTA;
